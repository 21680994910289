import Vue from "vue";
// import VueLazyload from 'vue-lazyload'
import App from "./App.vue";
import router from "./router";

import store from "@/vuex/store.js";

import http from "@/axios/api.js";

import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "@/assets/css/ele-common.css";

import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

import "@/rem";

import { fontAssetsURL } from "@/utils.js";
Vue.prototype.fontAssetsURL = fontAssetsURL;

// Vue.prototype.baseURL = "https://test.xiaoguoai.cn";
// Vue.prototype.baseURL = process.env.VUE_APP_BASE_URL;
// Vue.prototype.baseURL = 'https://xiaoguobx.com/'
Vue.prototype.baseURL = process.env.VUE_APP_BASE_URL;
// Vue.prototype.baseURL = "http://192.168.10.113:8083/";
Vue.prototype.$bus = new Vue();

Vue.use(ElementUI);
// Vue.use(VueLazyload, {
//   // preLoad: 1.3,
//   // error: 'dist/error.png',
//   loading: '/static/images/load.gif'
//   // attempt: 1
// })
Vue.config.productionTip = false;
Vue.prototype.$http = http;

import { Message } from "element-ui";
Vue.component(Message);
Vue.prototype.$message = Message;

router.beforeEach((to, from, next) => {
  if (to.name == "newsinfo" && to.query && to.query.title) {
    document.title = "晓果智学" + "-" + to.query.title;
    next();
    return;
  }
  if (to.meta.title) {
    document.title = "晓果智学" + "-" + to.meta.title;
  } else {
    document.title = "晓果智学——中小学智能个性化学习领导品牌 提分利器 智能教育 自适应学习";
  }
  next();
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
